/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActionLetter } from './ActionLetter';
import {
    ActionLetterFromJSON,
    ActionLetterFromJSONTyped,
    ActionLetterToJSON,
} from './ActionLetter';
import type { ActionUser } from './ActionUser';
import {
    ActionUserFromJSON,
    ActionUserFromJSONTyped,
    ActionUserToJSON,
} from './ActionUser';

/**
 * 
 * @export
 * @interface Action
 */
export interface Action {
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    type: ActionTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof Action
     */
    due_date: Date;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    description?: string | null;
    /**
     * 
     * @type {ActionUser}
     * @memberof Action
     */
    user?: ActionUser | null;
    /**
     * 
     * @type {string}
     * @memberof Action
     */
    state: ActionStateEnum;
    /**
     * 
     * @type {ActionLetter}
     * @memberof Action
     */
    letter?: ActionLetter | null;
}


/**
 * @export
 */
export const ActionTypeEnum = {
    Note: 'note',
    Contact: 'contact',
    GenerateLetter: 'generate_letter',
    GenerateLetterAck: 'generate_letter_ack',
    GenerateLetter4wk: 'generate_letter_4wk',
    GenerateLetter8wk: 'generate_letter_8wk',
    SentLetter: 'sent_letter',
    WaitingAssignment: 'waiting_assignment',
    AdjudicationAwaitCaseFile: 'adjudication_await_case_file',
    AdjudicationAwaitOutcome: 'adjudication_await_outcome',
    FinalAwaitCaseFile: 'final_await_case_file',
    FinalAwaitOutcome: 'final_await_outcome'
} as const;
export type ActionTypeEnum = typeof ActionTypeEnum[keyof typeof ActionTypeEnum];

/**
 * @export
 */
export const ActionStateEnum = {
    DueTo: 'App\\Models\\ActionState\\DueTo',
    PastDue: 'App\\Models\\ActionState\\PastDue',
    Closed: 'App\\Models\\ActionState\\Closed'
} as const;
export type ActionStateEnum = typeof ActionStateEnum[keyof typeof ActionStateEnum];


/**
 * Check if a given object implements the Action interface.
 */
export function instanceOfAction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "due_date" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function ActionFromJSON(json: any): Action {
    return ActionFromJSONTyped(json, false);
}

export function ActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Action {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'due_date': (new Date(json['due_date'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'user': !exists(json, 'user') ? undefined : ActionUserFromJSON(json['user']),
        'state': json['state'],
        'letter': !exists(json, 'letter') ? undefined : ActionLetterFromJSON(json['letter']),
    };
}

export function ActionToJSON(value?: Action | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'due_date': (value.due_date.toISOString().substring(0,10)),
        'description': value.description,
        'user': ActionUserToJSON(value.user),
        'state': value.state,
        'letter': ActionLetterToJSON(value.letter),
    };
}

