import React, { useEffect, useMemo } from 'react';
import { Button } from 'primereact/button';
import styled from 'styled-components';
import { fieldsPlaceholders } from '@/config/forms';
import { useForm } from 'react-hook-form';
import {
    type Complaint,
    ComplaintStateEnum,
    DecisionDecisionTypeEnum,
    RootCauseStatus,
    type UpsertComplaintDecisionRequest,
    UpsertComplaintDecisionRequestDecisionTypeEnum,
    UpsertComplaintDecisionRequestFinalLetterTypeEnum
} from '@/stub';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import BaseRadio from '@/components/Core/Form/BaseRadio';
import BaseTextEditor from '@/components/Core/Form/BaseTextEditor';
import BaseDropdown from '@/components/Core/Form/BaseDropdown';
import BaseNumberInput from '@/components/Core/Form/BaseNumberInput';
import BaseCheckbox from '@/components/Core/Form/BaseCheckbox';
import BaseTextInput from '@/components/Core/Form/BaseTextInput';
import BaseCalendar from '@/components/Core/Form/BaseCalendar';
import { Divider } from 'primereact/divider';
import { useUpdateComplaintDecision } from '@/Service/Api/ApiHooks/ComplaintDecision/useUpdateComplaintDecision';
import { useGetComplaintDecision } from '@/Service/Api/ApiHooks/ComplaintDecision/useGetComplaintDecision';
import { useToastMessagesStore } from '@/Stores/ToastMessagesStore';
import { ComplaintDecisionUpdatedMessage } from '@/Messages/Toast/ComplaintDecision/ComplaintDecisionUpdatedMessage';
import { useQueryClient } from '@tanstack/react-query';
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import ComplaintRootCauseSelect from "@/components/Core/Form/Selector/ComplaintRootCauseSelect";
import { formatToApiDate } from "@/Util/formatToApiDate";
import InfoTooltipTemplate from "@/components/templates/InfoTooltipTemplate";
import { isReadonlyComplaint } from "@/Util/permissionChecks";
import { useFormStateStore } from "@/Stores/FormStore";
import { isEmpty } from "@/Util/isEmptyObject";
import { normalizeContent } from "@/Util/normalizeContent";
import { SRC_ALLOWED_DAYS } from "@/config/constants";

const OutcomeWrap = styled.div`
    h4 {
        margin: 0 0 1rem 0;
    }
`;

const ComplaintDecisionSchema = z.object({
    decision_type: z.nativeEnum(UpsertComplaintDecisionRequestDecisionTypeEnum),
    final_letter_type: z.nativeEnum(UpsertComplaintDecisionRequestFinalLetterTypeEnum).default(UpsertComplaintDecisionRequestFinalLetterTypeEnum.Frl),
    summary: z.string().nullable().optional().default(''),
    refund: z.number().optional(),
    interest: z.number().optional(),
    financial_loss: z.number().optional(),
    non_financial_loss: z.number().optional(),
    ex_gratia: z.number().optional(),
    total: z.number().optional(),
    approval_level: z.string().nullable().optional().default(''),
    approved_by: z.string().nullable().optional().default(''),
    approval_date: z.date().optional(),
    root_cause_id: z.number({
        invalid_type_error: 'Root Cause is invalid'
    }),
    is_customer_accept_outcome: z.boolean().optional()
}).superRefine((data, ctx) => {
    if (data.final_letter_type === UpsertComplaintDecisionRequestFinalLetterTypeEnum.Src) {
        if (!data.is_customer_accept_outcome) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Customer needs to accept the outcome if SRC letter is selected',
                path: ['is_customer_accept_outcome']
            });
        }
    }
});

type ComplaintDecisionFormData = z.infer<typeof ComplaintDecisionSchema>;

const decisionTypeOptions = Object.values(DecisionDecisionTypeEnum);
const finalResponseTypeOptions = Object.values(UpsertComplaintDecisionRequestFinalLetterTypeEnum).map(type => ({
    value: type,
    label: type.toUpperCase()
}));

export type OutcomeProps = {
    complaint: Complaint
};

const sumFields = ['refund', 'interest', 'financial_loss', 'non_financial_loss', 'ex_gratia'];

const Outcome: React.FC<OutcomeProps> = ({
    complaint
}: OutcomeProps) => {
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);
    const queryClient = useQueryClient();
    const setDirty = useFormStateStore((state) => state.setDirty);
    const {
        data: decisionData
    } = useGetComplaintDecision(
        {
            requestParams: {
                complaint_id: complaint.id
            },
            enabled: complaint?.state === ComplaintStateEnum.Resolved || complaint?.state === ComplaintStateEnum.Closed,
        });

    const initialFormData: ComplaintDecisionFormData = useMemo(() => {
        const formData = {
            ...decisionData,
            summary: decisionData?.summary ?? '',
            approval_level: decisionData?.approval_level ?? '',
            approved_by: decisionData?.approved_by ?? ''
        };
        if (
            complaint?.state !== ComplaintStateEnum.Closed
            && decisionData?.root_cause?.status === RootCauseStatus.Inactive
        ) {
            return formData;
        }

        return {
            ...formData,
            root_cause_id: decisionData?.root_cause?.id
        };
    }, [complaint?.state, decisionData]);

    const {
        control,
        reset,
        handleSubmit,
        setValue,
        setError,
        watch,
        formState: { errors, dirtyFields }
    } = useForm<ComplaintDecisionFormData>({
        resolver: zodResolver(ComplaintDecisionSchema),
        defaultValues: useMemo(() => {
            return initialFormData;
        }, [initialFormData])
    });

    const updateComplaintDecisionMutation = useUpdateComplaintDecision({ setError });

    const validate = (data: ComplaintDecisionFormData) => {
        return ComplaintDecisionSchema.safeParse(data).success;
    };

    const onSubmit = async (data: ComplaintDecisionFormData) => {
        if (validate(data)) {
            const dataToSend: UpsertComplaintDecisionRequest = {
                ...data,
                approval_date: formatToApiDate(data.approval_date)
            };

            await updateComplaintDecisionMutation.mutateAsync({
                complaint_id: complaint.id,
                UpsertComplaintDecisionRequest: dataToSend
            }, {
                onSuccess: () => {
                    void Promise.all([
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.complaints.detail(complaint?.id).queryKey
                        }),
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.decision.detail({ complaint_id: complaint?.id }).queryKey
                        }),
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.activityLog.list({ complaint_id: complaint?.id }).queryKey
                        })
                    ]);
                    addToastMessage(ComplaintDecisionUpdatedMessage);
                },
                onError: error => {
                    addToastMessage(CustomErrorMessage(error));
                }
            });
        }
    };

    useEffect(() => {
        reset(normalizeContent(initialFormData));
    }, [initialFormData]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (sumFields.includes(name)) {
                let sum = 0;
                sumFields.forEach(field => {
                    sum += value[field] ?? 0;
                });

                setValue('total', sum);
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [watch]);

    const isDirty = !isEmpty(dirtyFields);
    useEffect(() => {
        setDirty(isDirty);
    }, [isDirty, setDirty]);

    return (<OutcomeWrap>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-5">
                <h4 className="font-semibold">Final Root Cause</h4>
                <div className="formgrid grid my-3 mx-2">
                    <div className="col-6 mt-3">
                        <ComplaintRootCauseSelect
                            className="w-full rounded-md"
                            name="root_cause_id"
                            label="Final Root Cause"
                            control={control}
                            disabled={isReadonlyComplaint(complaint)}
                            errorMessages={errors.root_cause_id?.message}
                            required
                        />
                    </div>
                </div>
            </div>

            <Divider/>

            <div className="mb-5">
                <h4 className="font-semibold">Decision</h4>
                <div className="formgrid grid my-3 mx-2 row-gap-3">
                    <div className="col-12">
                        <BaseRadio
                            control={control}
                            options={decisionTypeOptions}
                            required
                            disabled={isReadonlyComplaint(complaint)}
                            name="decision_type"
                            label="Decision Type"
                            errorMessages={errors?.decision_type?.message}
                        />
                    </div>
                    <div className="col-8 flex">
                        <BaseTextEditor
                            label='Summary'
                            textareaName='summary'
                            control={control}
                            menubar={false}
                            disabled={isReadonlyComplaint(complaint)}
                            errorMessages={errors?.summary?.message}
                        />
                        <InfoTooltipTemplate
                            target='summary'
                            message='This information will be used to populate the FRL letter'
                            className="mt-5 ml-2"
                        />
                    </div>
                    {complaint && complaint.work_days_open <= SRC_ALLOWED_DAYS &&
                        <div className="col-12">
                            <BaseDropdown
                                control={control}
                                name="final_letter_type"
                                label="Final response type"
                                placeholder={fieldsPlaceholders.dropdown}
                                required
                                disabled={isReadonlyComplaint(complaint)}
                                options={finalResponseTypeOptions}
                                errorMessages={errors.final_letter_type?.message}
                            />
                        </div>}
                </div>
            </div>

            <Divider/>

            <div className="mb-5">
                <h4 className="font-semibold">Redress</h4>

                <div className="formgrid grid my-3 mx-2 row-gap-2">
                    <div className="col-12">
                        <BaseNumberInput
                            control={control}
                            name="refund"
                            disabled={isReadonlyComplaint(complaint)}
                            label="Refund (fees/charges)"
                            placeholder={fieldsPlaceholders.floatZero}
                            mode='decimal'
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            type='decimal'
                            errorMessages={errors.refund?.message}
                        />
                    </div>
                    <div className="col-12">
                        <BaseNumberInput
                            control={control}
                            name="interest"
                            label="Interest"
                            disabled={isReadonlyComplaint(complaint)}
                            placeholder={fieldsPlaceholders.floatZero}
                            mode='decimal'
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            type='decimal'
                            errorMessages={errors.interest?.message}
                        />
                    </div>
                    <div className="col-12">
                        <BaseNumberInput
                            control={control}
                            name="financial_loss"
                            label="Financial Loss"
                            placeholder={fieldsPlaceholders.floatZero}
                            mode='decimal'
                            disabled={isReadonlyComplaint(complaint)}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            type='decimal'
                            errorMessages={errors.financial_loss?.message}
                        />
                    </div>
                    <div className="col-12">
                        <BaseNumberInput
                            control={control}
                            name="non_financial_loss"
                            label="Non-Financial Loss / D&I"
                            placeholder={fieldsPlaceholders.floatZero}
                            mode='decimal'
                            disabled={isReadonlyComplaint(complaint)}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            type='decimal'
                            errorMessages={errors.non_financial_loss?.message}
                        />
                    </div>
                    <div className="col-12">
                        <BaseNumberInput
                            control={control}
                            name="ex_gratia"
                            label="Ex-Gratia / Apology"
                            placeholder={fieldsPlaceholders.floatZero}
                            mode='decimal'
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            disabled={isReadonlyComplaint(complaint)}
                            type='decimal'
                            errorMessages={errors.ex_gratia?.message}
                        />
                    </div>
                    <div className="col-12">
                        <BaseNumberInput
                            control={control}
                            name="total"
                            disabled={isReadonlyComplaint(complaint)}
                            label="Total"
                            placeholder={fieldsPlaceholders.floatZero}
                            mode='decimal'
                            readOnly
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            type='decimal'
                            errorMessages={errors.total?.message}
                        />
                    </div>
                </div>
            </div>

            <Divider/>

            <div className="mb-5">
                <h4 className="font-semibold">Approval</h4>

                <div className="formgrid grid my-3 mx-2 row-gap-2">
                    {(complaint && complaint?.work_days_open <= SRC_ALLOWED_DAYS) && <div className="col-12">
                        <BaseCheckbox
                            control={control}
                            name="is_customer_accept_outcome"
                            checked={!!decisionData?.is_customer_accept_outcome}
                            disabled={isReadonlyComplaint(complaint)}
                            label="Customer accepted the outcome"
                            errorMessages={errors.is_customer_accept_outcome?.message}
                        />
                    </div>}
                    <div className="col-12">
                        <BaseTextInput
                            control={control}
                            name="approval_level"
                            label="Level of Approval Needed"
                            disabled={isReadonlyComplaint(complaint)}
                            placeholder={fieldsPlaceholders.text}
                            errorMessages={errors.approval_level?.message}
                        />
                    </div>
                    <div className="col-12">
                        <BaseTextInput
                            control={control}
                            name="approved_by"
                            disabled={isReadonlyComplaint(complaint)}
                            label="Approved By"
                            placeholder={fieldsPlaceholders.text}
                            errorMessages={errors.approved_by?.message}
                        />
                    </div>
                    <div className="col-12">
                        <BaseCalendar
                            control={control}
                            label="Date"
                            disabled={isReadonlyComplaint(complaint)}
                            name='approval_date'
                            placeholder={fieldsPlaceholders.date}
                            errorMessages={errors.approval_date?.message}
                        />
                    </div>
                </div>
            </div>

            <Divider/>

            <div className="flex">
                <Button
                    type="submit"
                    label='Submit'
                    className="ml-auto"
                    icon='pi pi-check-circle'
                    iconPos="right"
                    disabled={isReadonlyComplaint(complaint)}
                    loading={updateComplaintDecisionMutation.isPending}
                />
            </div>
        </form>
    </OutcomeWrap>);
};

export default Outcome;
