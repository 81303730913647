/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ComplaintPoints,
  GetPointsFromComplaint200Response,
  StoreComplaintPointRequest,
  UpsertComplaintInvestigationPointsRequest,
} from '../models/index';
import {
    ComplaintPointsFromJSON,
    ComplaintPointsToJSON,
    GetPointsFromComplaint200ResponseFromJSON,
    GetPointsFromComplaint200ResponseToJSON,
    StoreComplaintPointRequestFromJSON,
    StoreComplaintPointRequestToJSON,
    UpsertComplaintInvestigationPointsRequestFromJSON,
    UpsertComplaintInvestigationPointsRequestToJSON,
} from '../models/index';

export interface CreatePointInComplaintRequest {
    complaint_id: number;
    StoreComplaintPointRequest?: StoreComplaintPointRequest;
}

export interface DeletePointInComplaintRequest {
    complaint_id: number;
    point_id: number;
}

export interface GetPointFromComplaintRequest {
    complaint_id: number;
    point_id: number;
}

export interface GetPointsFromComplaintRequest {
    complaint_id: number;
}

export interface UpdatePointsInComplaintRequest {
    complaint_id: number;
    UpsertComplaintInvestigationPointsRequest?: UpsertComplaintInvestigationPointsRequest;
}

/**
 * 
 */
export class ComplaintPointsApi extends runtime.BaseAPI {

    /**
     * Create complaint point
     */
    async createPointInComplaintRaw(requestParameters: CreatePointInComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ComplaintPoints>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling createPointInComplaint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/points`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StoreComplaintPointRequestToJSON(requestParameters.StoreComplaintPointRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ComplaintPointsFromJSON(jsonValue));
    }

    /**
     * Create complaint point
     */
    async createPointInComplaint(requestParameters: CreatePointInComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ComplaintPoints> {
        const response = await this.createPointInComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete complaint point
     */
    async deletePointInComplaintRaw(requestParameters: DeletePointInComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling deletePointInComplaint.');
        }

        if (requestParameters.point_id === null || requestParameters.point_id === undefined) {
            throw new runtime.RequiredError('point_id','Required parameter requestParameters.point_id was null or undefined when calling deletePointInComplaint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/points/{point_id}`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))).replace(`{${"point_id"}}`, encodeURIComponent(String(requestParameters.point_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete complaint point
     */
    async deletePointInComplaint(requestParameters: DeletePointInComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePointInComplaintRaw(requestParameters, initOverrides);
    }

    /**
     * Get the complaint point info
     */
    async getPointFromComplaintRaw(requestParameters: GetPointFromComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ComplaintPoints>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling getPointFromComplaint.');
        }

        if (requestParameters.point_id === null || requestParameters.point_id === undefined) {
            throw new runtime.RequiredError('point_id','Required parameter requestParameters.point_id was null or undefined when calling getPointFromComplaint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/points/{point_id}`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))).replace(`{${"point_id"}}`, encodeURIComponent(String(requestParameters.point_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ComplaintPointsFromJSON(jsonValue));
    }

    /**
     * Get the complaint point info
     */
    async getPointFromComplaint(requestParameters: GetPointFromComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ComplaintPoints> {
        const response = await this.getPointFromComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of investigation points for a complaint
     */
    async getPointsFromComplaintRaw(requestParameters: GetPointsFromComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPointsFromComplaint200Response>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling getPointsFromComplaint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/points`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPointsFromComplaint200ResponseFromJSON(jsonValue));
    }

    /**
     * Get list of investigation points for a complaint
     */
    async getPointsFromComplaint(requestParameters: GetPointsFromComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPointsFromComplaint200Response> {
        const response = await this.getPointsFromComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create/Update/Delete complaint investigation points
     */
    async updatePointsInComplaintRaw(requestParameters: UpdatePointsInComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ComplaintPoints>>> {
        if (requestParameters.complaint_id === null || requestParameters.complaint_id === undefined) {
            throw new runtime.RequiredError('complaint_id','Required parameter requestParameters.complaint_id was null or undefined when calling updatePointsInComplaint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", ["bearerAuth"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/complaints/{complaint_id}/points`.replace(`{${"complaint_id"}}`, encodeURIComponent(String(requestParameters.complaint_id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpsertComplaintInvestigationPointsRequestToJSON(requestParameters.UpsertComplaintInvestigationPointsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ComplaintPointsFromJSON));
    }

    /**
     * Create/Update/Delete complaint investigation points
     */
    async updatePointsInComplaint(requestParameters: UpdatePointsInComplaintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ComplaintPoints>> {
        const response = await this.updatePointsInComplaintRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
