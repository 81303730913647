import React, { useMemo } from 'react';
import BaseTreeSelect, { type BaseTreeSelectProps } from '@/components/Core/Form/BaseTreeSelect';
import type { VulnerableCustomerCategory } from '@/stub';
import type { BaseFormInputProps } from '@/Type/BaseFormInputProps';
import { OptionsTree } from "@/Model/OptionsTree";
import {
    useGetVulnerableCustomerCategories
} from "@/Service/Api/ApiHooks/VulnerableCustomerCategory/useGetVulnerableCustomerCategories";
import { useWatch } from "react-hook-form";
import { findTreeNode } from "@/Util/findTreeNode";
import { clsx } from "clsx";

export type VulnerableCategorySelectProps = BaseTreeSelectProps & BaseFormInputProps;

const buildVulnerableCategoryTree = (vulnerableCategories: VulnerableCustomerCategory[]): OptionsTree<VulnerableCustomerCategory>[] => {
    if (vulnerableCategories?.length > 0) {
        return vulnerableCategories.map((vulnerableCategory) => {
            return new OptionsTree<VulnerableCustomerCategory>(
                vulnerableCategory.id,
                vulnerableCategory,
                vulnerableCategory.description,
                vulnerableCategory.parent_names,
                vulnerableCategory.selectable,
                buildVulnerableCategoryTree(vulnerableCategory.children ?? [])
            );
        });
    }
    return [];
};

const VulnerableCategorySelect: React.FC<VulnerableCategorySelectProps> = ({
    className,
    ...props
}: VulnerableCategorySelectProps) => {
    const {
        data: vulnerableCategories
    } = useGetVulnerableCustomerCategories({
        select: (vulnerableCategory: VulnerableCustomerCategory[]) => vulnerableCategory?.[0]?.children ?? []
    });

    const vulnerableCategoryOptions = useMemo(() => {
        if (vulnerableCategories) {
            return buildVulnerableCategoryTree(vulnerableCategories);
        }
        return [];
    }, [vulnerableCategories]);

    const controlledValue = useWatch({
        control: props.control,
        name: props.name ?? 'undefined',
    });

    const selectedValue = useMemo(() => {
        if (props.control) {
            return controlledValue;
        }
        return props.value;
    }, [controlledValue, props.control, props.value]);

    const vulnerableCategoryPath = useMemo(() => {
        if (!selectedValue || !vulnerableCategories) {
            return undefined;
        }
        const activeVulnerableCategory = findTreeNode(vulnerableCategories, selectedValue);
        if (activeVulnerableCategory) {
            return `${activeVulnerableCategory.parent_names} > ${activeVulnerableCategory.description}`;
        }
    }, [vulnerableCategories, selectedValue]);

    return (
        <div className={clsx("flex", className)}>
            <BaseTreeSelect
                {...props}
                selectionMode={'multiple'}
                options={vulnerableCategoryOptions}
            />
            {
                vulnerableCategoryPath && <div>{vulnerableCategoryPath}</div>
            }
        </div>
    );
};

export default VulnerableCategorySelect;
