/* tslint:disable */
/* eslint-disable */
/**
 * Forseti API
 *  ### Welcome to the Forseti API !   To use the Forseti API, you will need a `access_token` from **Bridgetech Support**  ## How to use swagger documentation   Click `Authorize` and paste your  `access_token` as `bearerAuth`  ## Design Notes 1. For non-mandatory fields, the API will allow you to submit `null` *or* omit fields entirely. 2. All query parameters are optional. Pagination fields default as follows: * page = 1 * limit = 20
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ActionType = {
    Note: 'note',
    Contact: 'contact',
    GenerateLetter: 'generate_letter',
    GenerateLetterAck: 'generate_letter_ack',
    GenerateLetter4wk: 'generate_letter_4wk',
    GenerateLetter8wk: 'generate_letter_8wk',
    SentLetter: 'sent_letter',
    WaitingAssignment: 'waiting_assignment',
    AdjudicationAwaitCaseFile: 'adjudication_await_case_file',
    AdjudicationAwaitOutcome: 'adjudication_await_outcome',
    FinalAwaitCaseFile: 'final_await_case_file',
    FinalAwaitOutcome: 'final_await_outcome'
} as const;
export type ActionType = typeof ActionType[keyof typeof ActionType];


export function ActionTypeFromJSON(json: any): ActionType {
    return ActionTypeFromJSONTyped(json, false);
}

export function ActionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionType {
    return json as ActionType;
}

export function ActionTypeToJSON(value?: ActionType | null): any {
    return value as any;
}

