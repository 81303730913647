import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { url } from '@/helpers/general';
import {
    BadgeCheckIcon,
    ClipboardListIcon,
    LetterIcon,
    MailIcon,
    MailOpenIcon,
    OfficeBuildingIcon
} from '@/helpers/svg-icons';
import { PanelMenu } from 'primereact/panelmenu';
import { useOrgId } from "@/Hooks/useOrgId";
import { MenuItem } from "primereact/menuitem";
import styled from "styled-components";
import { clsx } from "clsx";
import { authUserCan } from "@/Util/permissionChecks";
import { useGetFosComplaints } from "@/Service/Api/ApiHooks/ComplaintFos/useGetFosCompaints";
import { OAFosState } from "@/stub";
import { Badge } from "primereact/badge";

const StyledWrap = styled.div`
    .sidebar-link {
        padding: 0.75rem 1rem;
        color: var(--gray-600);

        &:hover {
            background-color: var(--gray-100)
        }

        &__top-level {
            font-weight: 600;
        }

        &__with-icon {
            padding: 0.375rem .5rem;
        }

        &__future-sign {
            margin-left: auto;
            font-weight: 500;
        }

        &.future-item {
            opacity: 0.4;
            pointer-events: none;
        }
    }
`;

const fosOutboxStates: OAFosState[] = [
    OAFosState.AdjudicationClosed,
    OAFosState.FinalDecisionClosed,
];

export const fosInboxStates: OAFosState[] = [
    OAFosState.NotificationReceived,
    OAFosState.AdjudicationRequested,
    OAFosState.AdjudicationSubmitted,
    OAFosState.FinalDecisionRequested,
    OAFosState.FinalDecisionSubmitted,
];

const Sidebar = () => {
    const orgId = useOrgId();
    const createRequestParams = (states: OAFosState[]) => ({
        fos_states: states,
    });


    const { data: complaintsOutbox } = useGetFosComplaints({
        requestParameters: createRequestParams(fosOutboxStates),
        enabled: authUserCan('read:fos')
    });

    const { data: complaintsInbox } = useGetFosComplaints({
        requestParameters: createRequestParams(fosInboxStates),
        enabled: authUserCan('read:fos')
    });

    const menuItemTemplateFos = (item: MenuItem, isOutbox: boolean) => {

        const className = clsx(
            'flex align-items-center sidebar-link',
            { 'sidebar-link__with-icon': item.icon },
            item.className
        );
        if (item.data?.future) {
            return (
                <div className={clsx(className, 'future-item')}>
                    {item.icon && item.icon()}
                    <span>{item.label}</span>
                    <span className="sidebar-link__future-sign">N/A</span>
                </div>
            );
        }

        const count = isOutbox
            ? complaintsOutbox?.meta?.total
            : complaintsInbox?.meta?.total;
        return (
            item.url
            && <NavLink
                to={item.url}
                className={className}
            >
                {item.icon && item.icon()}

                <div className="relative">
                    <span>{item.label}</span>
                    <Badge style={{ bottom: '10px' }} className="absolute" value={count}></Badge>
                </div>
            </NavLink>
        );
    };


    const menuItemTemplate = (item: MenuItem) => {
        const className = clsx(
            'flex align-items-center sidebar-link',
            { 'sidebar-link__with-icon': item.icon },
            item.className
        );
        if (item.data?.future) {
            return (
                <div className={clsx(className, 'future-item')}>
                    {item.icon && item.icon()}
                    <span>{item.label}</span>
                    <span className="sidebar-link__future-sign">N/A</span>
                </div>
            );
        }
        return (
            item.url
            && <NavLink
                to={item.url}
                className={className}
            >
                {item.icon && item.icon()}
                <span>{item.label}</span>
            </NavLink>
        );
    };

    const menuItems: MenuItem[] = useMemo(() => {
        return [
            {
                label: 'Inbox',
                icon: MailIcon,
                visible: authUserCan('read:complaints'),
                className: 'sidebar-link__top-level',
                url: url('inbox', { orgId: orgId }),
                template: menuItemTemplate
            },
            {
                label: 'Outbox',
                icon: MailOpenIcon,
                visible: authUserCan('read:complaints'),
                className: 'sidebar-link__top-level',
                url: url('outbox', { orgId: orgId }),
                template: menuItemTemplate
            },
            {
                label: 'Letters',
                visible: authUserCan('set:sent_letter_date'),
                className: 'sidebar-link__top-level',
                icon: LetterIcon,
                url: url('letters', { orgId: orgId }),
                template: menuItemTemplate
            },
            {
                label: 'KPIs',
                icon: ClipboardListIcon,
                visible: authUserCan('view:reports'),
                expanded: true,
                items: [
                    {
                        label: 'Work In Progress',
                        url: url('kpi-work-in-progress', { orgId: orgId }),
                        template: menuItemTemplate
                    },
                    {
                        label: 'Summary',
                        url: url('kpi-summary', { orgId: orgId }),
                        template: menuItemTemplate
                    },
                    {
                        label: 'Trends',
                        disabled: true,
                        data: {
                            future: true
                        },
                        template: menuItemTemplate
                    },
                    {
                        label: 'Root Cause',
                        url: url('root-cause', { orgId: orgId }),
                        template: menuItemTemplate
                    },
                    {
                        label: 'Products',
                        disabled: true,
                        data: {
                            future: true
                        },
                        template: menuItemTemplate
                    },
                ]
            },
            {
                label: 'Compliance',
                icon: BadgeCheckIcon,
                expanded: true,
                items: [
                    {
                        label: 'DSAR',
                        url: url('dsar', { orgId: orgId }),
                        visible: authUserCan('read:dsar_process'),
                        template: menuItemTemplate
                    },
                    {
                        label: 'Quality Assurance',
                        disabled: true,
                        data: {
                            future: true
                        },
                        template: menuItemTemplate
                    },
                    {
                        label: 'FOS Inbox',
                        url: url('fos-inbox', { orgId: orgId }),
                        visible: authUserCan('write:fos'),
                        template: (item: MenuItem) => menuItemTemplateFos(item, false),
                    },
                    {
                        label: 'FOS Outbox',
                        url: url('fos-outbox', { orgId: orgId }),
                        visible: authUserCan('write:fos'),
                        template: menuItemTemplate
                    },
                    {
                        label: 'FCA RMAR',
                        url: url('fca-rmar', { orgId: orgId }),
                        visible: authUserCan('view:reports'),
                        template: menuItemTemplate
                    },
                    {
                        label: 'GDPR',
                        disabled: true,
                        data: {
                            future: true
                        },
                        template: menuItemTemplate
                    }
                ]
            },
            {
                label: 'Organisation',
                icon: OfficeBuildingIcon,
                expanded: true,
                items: [
                    {
                        label: 'Customers',
                        url: url('customers', { orgId: orgId }),
                        visible: authUserCan('read:org_customers'),
                        template: menuItemTemplate
                    },
                    {
                        label: 'Users',
                        url: url('organisation-users', { orgId: orgId }),
                        visible: authUserCan('read:org_users'),
                        template: menuItemTemplate
                    },
                    {
                        label: 'Audit',
                        url: url('organisation-audit', { orgId: orgId }),
                        visible: authUserCan('read:org_audit'),
                        template: menuItemTemplate
                    },
                    {
                        label: 'Root Cause',
                        url: url('organisation-root-causes', { orgId: orgId }),
                        visible: authUserCan('edit:org_root_causes'),
                        template: menuItemTemplate
                    },
                    {
                        label: 'Deleted Complaints',
                        url: url('organisation-deleted-complaints', { orgId: orgId }),
                        visible: authUserCan('read:deleted_complaints'),
                        template: menuItemTemplate
                    },
                    {
                        label: 'Letter Templates',
                        disabled: true,
                        data: {
                            future: true
                        },
                        template: menuItemTemplate
                    },
                    {
                        label: 'Team',
                        disabled: true,
                        data: {
                            future: true
                        },
                        template: menuItemTemplate
                    },
                    {
                        label: 'Admin Tools',
                        disabled: true,
                        data: {
                            future: true
                        },
                        template: menuItemTemplate
                    },
                    {
                        label: 'Manage Products',
                        disabled: true,
                        data: {
                            future: true
                        },
                        template: menuItemTemplate
                    }
                ]
            }
        ];
    }, [complaintsInbox, complaintsOutbox, orgId]);
    return (
        <StyledWrap className="sidebar-wrap">
            <PanelMenu model={menuItems} multiple/>
        </StyledWrap>
    );
};

export default Sidebar;
