import { Middleware } from "@/stub";
import { FetchParams, RequestContext } from "auth0";
import { useAuthStore } from "@/Stores/AuthStore";

export const AppendOrganisationIdMiddleware: Middleware = {
    pre(context: RequestContext): Promise<FetchParams | void> {
        const organisation_id = useAuthStore.getState().impersonatedOrgId ?? useAuthStore.getState().authUser?.organisation_id;
        if (organisation_id) {
            switch (context.init.method) {
                case "DELETE":
                case "GET": {
                    const url = new URL(context.url.toString());
                    url.searchParams.set('organisation_id', organisation_id.toString());
                    context.url = url;
                    break;
                }
                case "POST":
                case "PATCH": {
                    let body = context.init.body;
                    if (typeof body === "string") {
                        body = JSON.parse(body);
                        body = { ...body, organisation_id: organisation_id };
                        context.init.body = JSON.stringify(body);
                    }
                    break;
                }
            }
        }
        return Promise.resolve(context);
    }
};
