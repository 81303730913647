import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { type Complaint, ComplaintCustomerContactType, type Customer, CustomerTypeEnum } from '@/stub';
import CustomerSelect from '@/components/Core/Form/Selector/CustomerSelect';
import { useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';
import { Button } from 'primereact/button';
import { zodResolver } from '@hookform/resolvers/zod';
import { Divider } from 'primereact/divider';
import BaseDropdown from '@/components/Core/Form/BaseDropdown';
import styled from 'styled-components';
import { type CustomerInfoFormData } from '@/components/Core/Customer/CustomerForm';
import { useCreateCustomer } from '@/Service/Api/ApiHooks/Customer/useCreateCustomer';
import { useUpdateComplaintCustomer } from '@/Service/Api/ApiHooks/Customer/useUpdateComplaintCustomer';
import { useQueryClient } from '@tanstack/react-query';
import { useToastMessagesStore } from '@/Stores/ToastMessagesStore';
import { ComplaintCustomerAttachedMessage } from '@/Messages/Toast/ComplaintCustomers/ComplaintCustomerAttachedMessage';
import { CustomErrorMessage } from "@/Messages/Toast/General/CustomErrorMessage";
import { QueryKeys } from "@/Service/Api/QueryKeys/QueryKeys";
import { ComplaintCustomerCreatedMessage } from "@/Messages/Toast/ComplaintCustomers/ComplaintCustomerCreatedMessage";
import { Message } from "primereact/message";
import BaseCheckbox from "@/components/Core/Form/BaseCheckbox";
import CustomerModal from "@/components/Modals/CustomerModal";
import { url } from "@/helpers/general";
import { NavLink } from "react-router-dom";
import { useOrgId } from "@/Hooks/useOrgId";

export type ComplaintContact = {
    label: string
    availableTypes: CustomerTypeEnum[]
};

export const CustomerContactTypes: Record<ComplaintCustomerContactType, ComplaintContact> = {
    [ComplaintCustomerContactType.Customer]: {
        label: 'Customer',
        availableTypes: [
            CustomerTypeEnum.Person
        ]
    },
    [ComplaintCustomerContactType.ThirdParty]: {
        label: 'Third Party',
        availableTypes: [
            CustomerTypeEnum.Person,
            CustomerTypeEnum.Organisation
        ]
    },
    [ComplaintCustomerContactType.Solicitor]: {
        label: 'Solicitor',
        availableTypes: [
            CustomerTypeEnum.Person,
            CustomerTypeEnum.Organisation
        ]
    },
    [ComplaintCustomerContactType.Company]: {
        label: 'Company',
        availableTypes: [
            CustomerTypeEnum.Organisation
        ]
    }
};

export type CorrespondenceOption = {
    contactType: ComplaintCustomerContactType
    label: string
};

const CorrespondenceOptionSchema = z.object({
    contactType: z.string(),
    label: z.string()
}, { invalid_type_error: 'Please select a correct correspondence' });

const CustomerSchema = z.object({
    id: z.number(),
    id_number: z.string(),
    display_name: z.string()
},
{ invalid_type_error: 'You must select an existing customer' }
);

const CustomerSelectionFormSchema = z.object({
    [ComplaintCustomerContactType.Customer]: CustomerSchema,
    [ComplaintCustomerContactType.ThirdParty]: CustomerSchema.optional(),
    [ComplaintCustomerContactType.Solicitor]: CustomerSchema.optional(),
    [ComplaintCustomerContactType.Company]: CustomerSchema.optional(),
    primaryCorrespondence: CorrespondenceOptionSchema
});

export type ComplaintContactsData =
    Record<ComplaintCustomerContactType, string | Customer & { display_name?: string } | undefined>
    & { primaryCorrespondence: CorrespondenceOption | null | undefined };

export type FinalizedComplaintContactsData =
    { [ComplaintCustomerContactType.Customer]: Customer }
    & Record<Exclude<ComplaintCustomerContactType, typeof ComplaintCustomerContactType.Customer>, Customer | undefined>
    & { primaryCorrespondence: CorrespondenceOption };

const StyledWrap = styled.div`
    .primary-correspondence {
        &__select {
            min-width: 16rem;
        }
    }
`;

const generateCustomerUrl = (url: string, customerIds: number[]) => {

    const params = new URLSearchParams();

    customerIds.forEach((id) => {
        params.append('customer_ids', id?.toString());
    });

    return url + '?' + params?.toString();

};

export type CustomerSelectionFormProps = {
    className?: string
    complaintContactsData?: FinalizedComplaintContactsData
    onSubmit?: (complaintContactsData: FinalizedComplaintContactsData) => void
    onBack?: () => void
    viewMode?: boolean
    customerTypeIndex?: number
    complaint?: Complaint,
    toggleEditMode?: (index: number) => void
};

const ComplaintContactsForm: React.FC<CustomerSelectionFormProps> = ({
    className,
    complaintContactsData,
    onSubmit,
    onBack,
    viewMode = false,
    customerTypeIndex,
    complaint,
    toggleEditMode
}: CustomerSelectionFormProps) => {
    const orgId = useOrgId();
    const queryClient = useQueryClient();
    const addToastMessage = useToastMessagesStore((state) => state.addToastMessage);

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm<ComplaintContactsData>({
        resolver: zodResolver(CustomerSelectionFormSchema),
        defaultValues: {
            ...complaintContactsData
        }
    });

    const complaintContactsFormState = useWatch({
        control
    });

    const mainCustomer = useWatch({
        control,
        name: ComplaintCustomerContactType.Customer
    });

    const [openedComplaintConfirmation, setOpenedComplaintConfirmation] = useState(false);

    const additionalComplaintConfirmationRequired = useMemo<boolean>(() => {
        return Boolean(typeof mainCustomer === 'object'
            && mainCustomer?.opened_complaints_count
            && mainCustomer?.opened_complaints_count > 0);
    }, [mainCustomer]);

    const correspondenceOptions = useMemo(() => {
        const {
            primaryCorrespondence,
            ...contacts
        } = complaintContactsFormState;

        const options: CorrespondenceOption[] = [];
        let contactType: ComplaintCustomerContactType;
        for (contactType in contacts) {
            const contact = complaintContactsFormState[contactType];
            if (
                contact && // Check that contact is not null or undefined
                typeof contact === 'object' &&
                'id' in contact
            ) {
                options.push({
                    contactType,
                    label: CustomerContactTypes[contactType].label
                });
            }
        }

        return options;
    }, [complaintContactsFormState]);

    useEffect(() => {
        // Let's try to find if the currently selected correspondence option still exists in the available options
        // If not then we need to reset it for friendly UX experience
        const correspondenceOption = complaintContactsFormState.primaryCorrespondence;
        if (
            correspondenceOption &&
            !correspondenceOptions.find(option => correspondenceOption.contactType === option.contactType)
        ) {
            setValue('primaryCorrespondence', null);
        }
    }, [complaintContactsFormState.primaryCorrespondence, correspondenceOptions, setValue]);

    const validate = (data: ComplaintContactsData) => {
        return CustomerSelectionFormSchema.safeParse(data).success;
    };

    const onCustomersSelectSubmit = (data: ComplaintContactsData) => {
        if (validate(data) && onSubmit) {
            onSubmit(data as FinalizedComplaintContactsData);
        }
    };

    const [customerInfoFormVisible, setCustomerInfoFormVisible] = useState(false);
    const [customerInfoContactType, setCustomerInfoContactType] = useState<ComplaintCustomerContactType>();

    const customerInfoInitData = useMemo<{ id_number: string } | undefined>(() => {
        if (customerInfoContactType) {
            const contactTypeData = complaintContactsFormState[customerInfoContactType];
            if (typeof contactTypeData === 'string') {
                return { id_number: contactTypeData };
            }
        }
        return undefined;
    }, [complaintContactsFormState, customerInfoContactType]);

    const onCustomerInfoFormOpen = (contactType: ComplaintCustomerContactType) => {
        setCustomerInfoFormVisible(true);
        setCustomerInfoContactType(contactType);
    };

    const createCustomerMutation = useCreateCustomer();

    const onCustomerCreate = (customerInfoFormData: CustomerInfoFormData) => {
        createCustomerMutation.mutate(
            {
                StoreCustomerRequest: {
                    ...customerInfoFormData,
                    vulnerable_category_ids: Object.keys(customerInfoFormData.vulnerable_category_ids).map(Number)
                }
            },
            {
                onSuccess: async (newCustomer) => {
                    if (customerInfoContactType) {
                        setValue(customerInfoContactType, newCustomer);
                    }
                    setCustomerInfoFormVisible(false);
                    void Promise.all([
                        queryClient.invalidateQueries({
                            queryKey: QueryKeys.customers._def
                        })
                    ]);
                    addToastMessage(ComplaintCustomerCreatedMessage);
                    if (viewMode) {
                        await updateCustomersList(newCustomer, (Object.keys(CustomerContactTypes) as ComplaintCustomerContactType[])[customerTypeIndex]);
                    }
                },
                onError: error => {
                    addToastMessage(CustomErrorMessage(error));
                }
            }
        );
    };

    const updateComplaintCustomers = useUpdateComplaintCustomer();

    const updateCustomersList = async (data, type) => {
        if (!complaint) {
            throw new Error('Complaint is not defined');
        }
        await updateComplaintCustomers.mutateAsync({
            complaint_id: complaint.id,
            UpdateComplaintCustomerRequest: {
                customers: [
                    {
                        customer_id: data.id,
                        type
                    }
                ]
            }
        }, {
            onSuccess: () => {
                if (toggleEditMode && customerTypeIndex) {
                    toggleEditMode(customerTypeIndex);
                }
                void Promise.all([
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.customers.list({ complaint_id: complaint.id }).queryKey
                    }),
                    queryClient.invalidateQueries({
                        queryKey: QueryKeys.activityLog.list({ complaint_id: complaint.id }).queryKey
                    })
                ]);
                addToastMessage(ComplaintCustomerAttachedMessage);
            },
            onError: error => {
                addToastMessage(CustomErrorMessage(error));
            }
        });
    };

    useEffect(() => {
        if (mainCustomer) {
            setValue('primaryCorrespondence', {
                contactType: ComplaintCustomerContactType.Customer,
                label: CustomerContactTypes.customer.label
            });
        }
    }, [mainCustomer, setValue]);

    return (
        <StyledWrap className={className}>
            <form onSubmit={handleSubmit(onCustomersSelectSubmit)}>
                <div className="flex flex-column gap-2">
                    {
                        (Object.keys(CustomerContactTypes) as ComplaintCustomerContactType[]).map((customerContactType, key) => {
                            if ((customerTypeIndex !== undefined && customerTypeIndex === key) || customerTypeIndex === undefined) {
                                return <Fragment key={customerContactType}>
                                    <div className="flex align-items-end gap-2">
                                        <CustomerSelect
                                            forceSelection={false}
                                            name={customerContactType}
                                            value={getValues(customerContactType) as Customer | string | undefined}
                                            control={control}
                                            errorMessages={errors[customerContactType]?.message}
                                            readOnly={viewMode && customerContactType === ComplaintCustomerContactType.Customer}
                                            required={customerContactType === ComplaintCustomerContactType.Customer}
                                            label={`${CustomerContactTypes[customerContactType].label} ID`}
                                            types={CustomerContactTypes[customerContactType].availableTypes}
                                            preprocessChange={(e) => {
                                                if (e.value === '') {
                                                    e.value = undefined;
                                                }
                                                return e;
                                            }}
                                            {...(viewMode
                                                ? {
                                                    onSelect: (event) => {
                                                        updateCustomersList(event.value, customerContactType);
                                                    }
                                                }
                                                : {})}
                                        />
                                        {
                                            typeof complaintContactsFormState[customerContactType] === 'string'
                                            && complaintContactsFormState[customerContactType]
                                            && <Button type="button" label="New" onClick={() => {
                                                onCustomerInfoFormOpen(customerContactType);
                                            }}/>
                                        }
                                        {
                                            typeof complaintContactsFormState[customerContactType] === 'object' &&
                                            <NavLink
                                                to={generateCustomerUrl(url('customers', { orgId }), [complaintContactsFormState[customerContactType]?.id])}>
                                                <Button label="View" icon="pi pi-user-edit"/>
                                            </NavLink>
                                        }
                                    </div>
                                    {
                                        customerContactType === ComplaintCustomerContactType.Customer
                                        && additionalComplaintConfirmationRequired
                                        && <div>
                                            <Message severity="warn" text={
                                                <div>
                                                    <p className="m-0">The customer already has opened complaints.</p>
                                                    <p className="m-0">Confirm additional complaint creation.</p>
                                                </div>
                                            }/>
                                            <BaseCheckbox
                                                label="Confirm"
                                                checked={openedComplaintConfirmation}
                                                onChange={(e) => {
                                                    setOpenedComplaintConfirmation(Boolean(e.checked));
                                                }}
                                            />
                                        </div>
                                    }
                                    {!viewMode && <Divider/>}
                                </Fragment>;
                            }
                        })
                    }
                    {!viewMode && <div className="primary-correspondence">
                        <BaseDropdown
                            className="primary-correspondence__select"
                            name="primaryCorrespondence"
                            control={control}
                            label="Default correspondence"
                            options={correspondenceOptions}
                            optionLabel="label"
                            errorMessages={errors.primaryCorrespondence?.message}
                        />
                    </div>}
                    <div className="flex">
                        {
                            onBack && <Button
                                label="Back"
                                className="mr-auto"
                                severity="secondary"
                                icon="pi pi-arrow-left"
                                onClick={() => {
                                    onBack();
                                }}
                            />
                        }
                        {
                            onSubmit && <Button
                                label="Next"
                                className="ml-auto"
                                disabled={additionalComplaintConfirmationRequired && !openedComplaintConfirmation}
                                icon="pi pi-arrow-right"
                                iconPos="right"
                                type="submit"
                            />
                        }
                    </div>
                </div>
            </form>
            <CustomerModal
                dialogProps={{
                    visible: customerInfoFormVisible,
                    onHide: () => {
                        setCustomerInfoFormVisible(false);
                    }
                }}
                customerFormProps={{
                    availableCustomerTypes: customerInfoContactType ? CustomerContactTypes[customerInfoContactType].availableTypes : undefined,
                    initFormData: customerInfoInitData,
                    onSubmit: (data) => {
                        onCustomerCreate(data);
                    },
                    onSubmitButtonLabel: "Create",
                    isProcessing: createCustomerMutation.isPending,
                    errorBag: createCustomerMutation.isError ? [createCustomerMutation.error] : undefined
                }}
            />
        </StyledWrap>
    );
};

export default ComplaintContactsForm;
