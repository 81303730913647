import React, { useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';
import { ChannelType, type Complaint, ComplaintStateEnum, RootCauseStatus } from '@/stub';
import { useGetProductCategories } from '@/Service/Api/ApiHooks/ProductCategory/useGetProductCategories';
import BaseCalendar from '@/components/Core/Form/BaseCalendar';
import { Button } from 'primereact/button';
import BaseDropdown from '@/components/Core/Form/BaseDropdown';
import BaseTextEditor from '@/components/Core/Form/BaseTextEditor';
import { COMPLAINT_ALLOWED_EXTENSIONS, FILE_UPLOAD_MAX_SIZE } from '@/config/constants';
import { zodResolver } from '@hookform/resolvers/zod';
import { useValidationErrors } from '@/Hooks/useValidationErrors';
import ComplaintChannelSelect from '@/components/Core/Form/Selector/ComplaintChannelSelect';
import { Divider } from "primereact/divider";
import { authUserCan, isReadonlyComplaint } from "@/Util/permissionChecks";
import ComplaintRootCauseSelect from "@/components/Core/Form/Selector/ComplaintRootCauseSelect";
import BaseProductSelect from "@/components/Core/Form/Selector/BaseProductSelect";
import { useFormStateStore } from "@/Stores/FormStore";
import { isEmpty } from "@/Util/isEmptyObject";

const today = new Date();

const ComplaintInfoFormSchema = z.object({
    raised_date: z.date({
        required_error: 'Raised date is required',
        invalid_type_error: 'Raised Date must be a date'
    }).optional(),
    summary: z.string({
        required_error: 'Summary is required',
        invalid_type_error: 'Summary must be a string'
    }),
    category_id: z.number({
        required_error: 'Category is required',
        invalid_type_error: 'Category is invalid'
    }),
    product_id: z.number({
        required_error: 'Product is required',
        invalid_type_error: 'Product is invalid'
    }),
    root_cause_id: z.coerce.number({
        required_error: 'Root Cause is required',
        invalid_type_error: 'Root Cause is invalid'
    }),
    channel: z.object({
        content: z.string({
            invalid_type_error: 'Content must be a string'
        }).optional(),
        from: z.string({
            invalid_type_error: 'From must be a string'
        }).optional(),
        to: z.string({
            invalid_type_error: 'To must be a string'
        }).optional(),
        description: z.string({
            invalid_type_error: 'Description must be a string'
        }).optional(),
        channel_type: z.nativeEnum(ChannelType, {
            required_error: 'Channel is required',
            invalid_type_error: 'Channel type must be a string'
        }).optional(),
        files: z.array(z.instanceof(File))
            .optional()
            .refine((files?: File[]) => !files || files[0].size <= FILE_UPLOAD_MAX_SIZE, `Max upload size is ${FILE_UPLOAD_MAX_SIZE / 1048576} MB`)
            .refine((files?: File[]) => !files || COMPLAINT_ALLOWED_EXTENSIONS.includes(files[0].type ?? ''), 'File format not supported')
    }).optional()
});

export type ComplaintInfoFormData = z.infer<typeof ComplaintInfoFormSchema>;

export type ComplaintInfoFormProps = {
    className?: string
    onSubmit?: (complaintFormData: ComplaintInfoFormData) => void
    onBack?: () => void
    lastStep?: boolean
    initFormData?: ComplaintInfoFormData
    viewMode?: boolean
    complaint?: Complaint
    isProcessing?: boolean
    errorBag?: Error[]
};

const ComplaintInfoForm: React.FC<ComplaintInfoFormProps> = ({
    className,
    onSubmit,
    onBack,
    lastStep = false,
    viewMode = false,
    complaint,
    isProcessing,
    errorBag
}: ComplaintInfoFormProps) => {
    const isCreateMode = !complaint;
    const setDirty = useFormStateStore((state) => state.setDirty);
    const ComplaintInfoFormRefinedSchema = useMemo(() => {
        return ComplaintInfoFormSchema.superRefine((data, ctx) => {
            if (!viewMode) {
                if (!data.channel?.channel_type) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: 'Channel is required',
                        path: ['channel.channel_type']
                    });
                }
            }
        });
    }, [viewMode]);

    const formDefaultValues = useMemo(() => {
        const formData = {
            category_id: complaint?.product.category_id,
            product_id: complaint?.product.id,
            summary: complaint?.summary,
            raised_date: complaint?.raised_date,
            channel: complaint?.channel,
        };

        if (
            complaint
            && complaint.state !== ComplaintStateEnum.Closed
            && complaint.root_cause.status === RootCauseStatus.Inactive
        ) {
            return formData;
        }

        return {
            ...formData,
            root_cause_id: complaint?.root_cause.id,
        };
    }, [complaint]);

    const {
        control,
        handleSubmit,
        setError,
        setValue,
        reset,
        formState: { errors, dirtyFields }
    } = useForm<ComplaintInfoFormData>({
        resolver: zodResolver(ComplaintInfoFormRefinedSchema),
        defaultValues: formDefaultValues
    });

    useValidationErrors(setError, errorBag);

    const raisedDateChangeBlocked = useMemo(() => {
        if (authUserCan('update:closed_complaint')) {
            return false;
        }
        return (viewMode && !authUserCan('change:raised_date'))
            || complaint?.state === ComplaintStateEnum.Closed;
    }, [complaint?.state, viewMode]);

    const {
        data: productCategories,
        isLoading: productCategoriesLoading
    } = useGetProductCategories({});

    const categoryId = useWatch({
        control,
        name: 'category_id'
    });

    const validate = (data: ComplaintInfoFormData) => {
        return ComplaintInfoFormSchema.safeParse(data).success;
    };

    const onComplaintInfoSubmit = (data: ComplaintInfoFormData) => {
        if (validate(data)) {
            if (onSubmit) {
                onSubmit(data);
            }
        }
    };

    useEffect(() => {
        if (formDefaultValues) {
            reset(formDefaultValues);
        }
        if(complaint) {
            reset();
        }
    }, [complaint, formDefaultValues, reset]);

    const isDirty = !isEmpty(dirtyFields);
    useEffect(() => {
        if (!isCreateMode) {
            setDirty(isDirty);
        }
    }, [isDirty, isCreateMode, setDirty]);

    return (
        <div className={className}>
            <form onSubmit={handleSubmit(onComplaintInfoSubmit)}>
                <div className="complaint-info flex flex-column gap-3">
                    <div className="flex flex-column gap-4">
                        <div className="w-3">
                            <BaseCalendar
                                name="raised_date"
                                label="Complaint raised date"
                                control={control}
                                maxDate={complaint?.created_at ?? today}
                                required
                                disabled={raisedDateChangeBlocked || isReadonlyComplaint(complaint)}
                                errorMessages={errors.raised_date?.message}
                            />
                        </div>

                        <BaseDropdown
                            className="complaint-info__category-select"
                            name="category_id"
                            label="Product category"
                            control={control}
                            options={productCategories}
                            optionValue="id"
                            optionLabel="description"
                            loading={productCategoriesLoading}
                            required
                            onChange={(e) => {
                                if (e.target.value) {
                                    setValue('category_id', e.target.value);
                                    setValue('product_id', null);
                                }
                            }}
                            disabled={isReadonlyComplaint(complaint)}
                            errorMessages={errors.category_id?.message}
                        />
                        <BaseProductSelect
                            className="complaint-info__product-select"
                            name="product_id"
                            label="Product name"
                            disabled={!categoryId || isReadonlyComplaint(complaint)}
                            control={control}
                            needCategory

                            categoryId={categoryId}
                            required
                            errorMessages={errors.product_id?.message}
                        />
                        <ComplaintRootCauseSelect
                            className="complaint-info__root-cause-select rounded-md"
                            name="root_cause_id"
                            label="Root cause"
                            control={control}
                            disabled={isReadonlyComplaint(complaint)}
                            errorMessages={errors.root_cause_id?.message}
                            required
                        />
                        <BaseTextEditor
                            textareaName="summary"
                            control={control}
                            label="Complaint summary"
                            disabled={isReadonlyComplaint(complaint)}
                            errorMessages={errors.summary?.message}
                        />

                        {!viewMode && <ComplaintChannelSelect
                            control={control}
                            required
                            errors={errors}
                        />}
                    </div>
                    <Divider/>
                    <div className="flex">
                        {
                            onBack && <Button
                                type="button"
                                label="Back"
                                className="mr-auto"
                                severity="secondary"
                                icon="pi pi-arrow-left"
                                onClick={() => {
                                    onBack();
                                }}
                            />
                        }
                        {
                            onSubmit && <Button
                                type="submit"
                                label={(lastStep || viewMode) ? 'Submit' : 'Next'}
                                className="ml-auto"
                                icon={(lastStep || viewMode) ? 'pi pi-check-circle' : 'pi pi-arrow-right'}
                                iconPos="right"
                                disabled={isReadonlyComplaint(complaint)}
                                loading={isProcessing}
                            />
                        }
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ComplaintInfoForm;
